var mapHandle; 
var infoWindowHandle;

function setGetLocationClickEvent(){
  const getLocationButton = document.getElementById('getLocationButton')
			if(getLocationButton){
				getLocationButton.addEventListener('click',(evnt)=>{
					evnt.preventDefault();
					getLocation();
				})
			}
}

function initMap() {
  console.debug('in proper initmap')
  let myLatlng = { lat: 38.363, lng: 32.044 };
  const initialLatLng = document.getElementById('initialLatLng');
  console.log(initialLatLng)
  if(initialLatLng){
    myLatlng.lat=Number(initialLatLng.dataset.lat);
    myLatlng.lng = Number(initialLatLng.dataset.lng);
  }
  const map = new google.maps.Map(document.getElementById("googleMapsContent"), {
    zoom: 4,
    center: myLatlng,
  });


  mapHandle = map;

  // Create the initial InfoWindow.
  let infoWindow = new google.maps.InfoWindow({
    content: "Click the map to get Lat/Lng!",
    position: myLatlng,
  });

  infoWindowHandle=infoWindow;

  infoWindow.open(map);
  // Configure the click listener.
  map.addListener("click", mapClickHandler)
}

window.addEventListener('DOMContentLoaded',(event)=>{
  console.debug('setting initmap..')
  window.pageData.initMap = initMap
  setGetLocationClickEvent()
})


let mapClickHandler = (mapsMouseEvent) => {
  // Close the current InfoWindow.
  infoWindowHandle.close();
  // Create a new InfoWindow.
  infoWindowHandle = new google.maps.InfoWindow({
    position: mapsMouseEvent.latLng,
  });
  infoWindowHandle.setContent(
    JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
  );
  document.getElementById('latitude').value=mapsMouseEvent.latLng.lat()
  document.getElementById('longitude').value=mapsMouseEvent.latLng.lng()
  
  infoWindowHandle.open(mapHandle);
}

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    alert("Can not access geolocation. Please use other options.");
  }
}

function showPosition(position) {
  document.getElementById('latitude').value=position.coords.latitude
  document.getElementById('longitude').value=position.coords.longitude
  const positionObj = {
    lat:position.coords.latitude,
    lng:position.coords.longitude
  }
  mapHandle.setCenter(positionObj)
  infoWindowHandle.close();
  infoWindowHandle = new google.maps.InfoWindow({
    position: positionObj,
  });
  infoWindowHandle.setContent(JSON.stringify(positionObj));
  infoWindowHandle.open(mapHandle);
}